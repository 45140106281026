<template>
  <b-card no-body>
    <!-- User Interface controls -->
    <b-card-header class="bg-light">
      <b-row>
        <b-col md="6" xl="5">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col md="3" xl="2" class="offset-xl-3 col-form-label"><strong>Total Users: {{totalRows}}</strong></b-col>

        <b-col md="3" xl="2">
          <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
            <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-header>

    <b-table
      class="card-table"
      show-empty
      hover
      :fields="userTableFields"
      :items="userTableItems"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :sort-compare="sortCompare"
      responsive
      :busy="tableBusy"
      >
      <template #cell(employee_id)="data">
        <router-link
          v-if="data.item.employee_id"
          :to="`/users/${data.item.id}`">{{ data.item.employee_id }}</router-link>
      </template>
      <template #cell(role)="data">
        {{ getLabel(data.item.role, roles) }}
      </template>
    </b-table>

    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        class="my-0"
      ></b-pagination>
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      userTableFields: [
        { key: 'employee_id', sortable: true },
        { key: 'email', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'first_name', sortable: true },
        // { key: 'supervisor_id', label: 'Manager Id', sortable: true },
        { key: 'role', sortable: true },
        { key: 'last_login', sortable: true },
      ],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      sortCompare: (itemA, itemB, key) => {
        if (['last_login'].indexOf(key) > -1) {
          let a = ((itemA[key] === null || itemA[key] === 'Never') ? '00/00/0000, 00:00 AM' : itemA[key]).split(',')
          let b = ((itemB[key] === null || itemB[key] === 'Never') ? '00/00/0000, 00:00 AM' : itemB[key]).split(',')
          let d1 = a[0].split('/')
          d1 = (parseInt(d1[2], 10) * 100000000) + (parseInt(d1[0], 10) * 1000000) + (parseInt(d1[1]) * 10000)
          let d2 = b[0].split('/')
          d2 = (parseInt(d2[2], 10) * 100000000) + (parseInt(d2[0], 10) * 1000000) + (parseInt(d2[1]) * 10000)
          let t1 = a[1].split(' ')
          t1 = (t1[2] === 'PM' && parseInt(t1[1].split(':')[0]) !== 12)
            ? (parseInt(t1[1].split(':')[0], 10) * 100) + parseInt(t1[1].split(':')[1]) + 1200
            : (parseInt(t1[1].split(':')[0], 10) * 100) + parseInt(t1[1].split(':')[1])
          let t2 = b[1].split(' ')
          t2 = (t2[2] === 'PM' && parseInt(t2[1].split(':')[0]) !== 12)
            ? (parseInt(t2[1].split(':')[0], 10) * 100) + parseInt(t2[1].split(':')[1]) + 1200
            : (parseInt(t2[1].split(':')[0], 10) * 100) + parseInt(t2[1].split(':')[1])
          a = d1 + t1
          b = d2 + t2
          return a - b
        } else {
          return false
        }
      },
      tableBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      filter: null,
    }
  },
  computed: {
    ...mapState('admin', [
      'roles'
    ]),
    ...mapGetters('admin', [
      'getLabel',
    ]),
    ...mapGetters('ping', [
      'reportingUsers',
    ]),
    userTableItems() {
      return this.reportingUsers.map(user => {
        const {
          // createdAt,
          email,
          enabled,
          // environment,
          id,
          // lastSignOn,
          // lifecycle,
          // locale,
          // mfaEnabled,
          name,
          // nickname,
          // population,
          // preferredLanguage,
          // primaryPhone,
          // profile,
          // salesforceCustomerID,
          // salesforceUserData,
          // timezone,
          // title,
          // updatedAt,
          // username
          // _links
        } = user;
        const item = {
          id,
          employee_id: user?.profile?.employeeID,
          first_name: name?.given,
          last_name: name?.family,
          email,
          // supervisor_id: user?.profile?.supervisorID,
          location: user?.profile?.location,
          reporting_users: user?.profile?.reportingUsers?.length,
          role: user?.profile?.role,
          last_login: user?.lastSignOn?.at ? this.formatUTCtoLocal(user?.lastSignOn?.at) : 'Never',
          enabled,
        };
        return item;
      })
    }
  },
  methods: {
    ...mapActions('ping', [
      'fetchIDAUsers'
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatUTCtoLocal(utc) {
      return (new Date(utc))?.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    },
  },
  mounted() {
    this.tableBusy = true;
    this.fetchIDAUsers()
      .then(() => {
        this.tableBusy = false;
        this.totalRows = this.reportingUsers.length
      })
  }
}
</script>
